.main-div{
    background-color: #F3F1FD;
    padding-top: 30px;
    padding-bottom: 40px;

}

.r-title{
    text-align: center;
    font-size: 25px;
    font-weight: 400;
    color: #000;
    margin-bottom: 20px;
}

.main-review-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    align-items: center;
    background-color: #F3F1FD;
}

.main-review-container a{
    text-decoration: none;
    max-width: 20%;    
    border: 2px solid #C8C1F7;
    border-radius: 8px;
}

@media screen and (max-width: 768px){
    .main-review-container a{
        max-width: 40%;
    }
    
}

@media screen and (max-width: 480px){
    .main-review-container a{
        max-width: 80%;
    }
    
}

@media screen and (max-width: 380px){
    .main-review-container a{
        max-width: 100%;
    }
    
}