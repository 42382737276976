// Overview
.p-course-data {
    font-size: 15px;
    padding: 10px 0px;
    color: #696969;
}

// FAQ
.acc-btn {
    font-size: 14px !important;
}

.acc-content {
    font-size: 13px !important;
    color: #696969 !important;
    line-height: 25px !important;
}

// Review
.course-review {
    padding: 0px;

    .review-count {
        font-size: 13px;
    }

    .review-detail img {
        border-radius: 50%;
    }

    .review-detail .review-name {
        font-size: 12px;
        font-weight: 500;
    }

    .review-detail .review-name .review-time {
        font-size: 11px;
        color: #696969;
        padding-left: 15px;
        display: inline;
    }

    .review-dec-card {
        background-color: #F1F2F8;
        padding: 20px;
        margin-left: 50px;
        border-radius: 10px;
    }

    .review-dec {
        font-size: 13px;
        color: #696969;
        line-height: 23px;
    }

    .cap-img img,
    .review-detail img {
        width: 35px;
        border-radius: 50%;
    }
}

// Special Study Notes
.course-study-note {
    padding-left: 0px;
}

.course-study-note .page-count {
    font-size: 13px;
    color: #696969;
    margin: 10px 0px;
}

.study-notes-container {
    position: relative;
    padding: 20px;
}

.iframe-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.iframe-wrapper {
    position: relative;
    width: 100%;
    height: 600px;
}

.blur-overlay {
    // position: absolute;
    // top: 0;
    // left: 0;
    width: 100%;
    // height: 100%;
    // backdrop-filter: blur(5px);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
}

.blur-overlay-pdf{
    width: 100%;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 160px;
}

.overlay-content {
    display: flex;
    // width: 90%;
    // height: 70%;
    text-align: center;
    padding: 45px 70px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #eeeeee;
    border: 2px solid #CE45ED;
    margin: 10px;
}

.overlay-content h1 {
    font-size: 20px;
    font-weight: 500;
}

.overlay-content p {
    font-size: 15px;
    color: #696969;
}

.overlay-content-list {
    // line-height: 30px;
}

.overlay-content-item {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 20px;
}

.overlay-content-item-icon {
    font-size: 15px;
    color: #8FCA94;
}

@media screen and (max-width: 768px) {
    // .pdf-comp-stack-container{
    //     margin: 30px 75px;
    // }
}

@media (max-width:425px) {

    // Review
    .course-review {
        .review-dec-card {
            margin-left: 0px;
        }
    }
        .overlay-content {
            padding: 30px;
        }
    .overlay-content p {
        font-size: 13px;
        color: #696969;
    }


    .overlay-content-list {
        line-height: 20px;
    }
}

@media (max-width:320px) {

    // Review
    .course-review {
        .review-detail .review-name .review-time {
            display: block;
            padding-left: 0px;
        }

        // .review-detail .review-name {
        //     display: flex;
        //     gap: 25px;
        //     align-items: center;
        // }
    }

    .overlay-content h1 {
        font-size: 15px;
    }

    .overlay-content p {
        font-size: 10px;
    }

    .button {
        padding: 1.1rem 1.5rem;
    }

    .overlay-content-list {
        line-height: 20px;
    }

}











// 
// 
.pdf-preview-container {
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    height: 90vh;
    overflow-y: auto;
}

.pdf-preview {
    display: flex;
    flex-direction: column;
}

.pdf-page-wrapper {
    position: relative;
    margin-bottom: 20px;
}

.blurred {
    filter: blur(8px);
    pointer-events: none;
    position: relative;
    z-index: 1;
    /* Ensure it is below the button */
}

.pay-now-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
    /* Ensure it is above the blurred content */
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #fff;
    background: linear-gradient(45deg, #ff6a00, #ee0979);
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.pay-now-btn:hover {
    background: linear-gradient(45deg, #ee0979, #ff6a00);
}

.pdf-scrollable {
    height: 100vh;
    overflow-y: auto;
}

.pdf-page-wrapper .react-pdf__Page {
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
}

.react-pdf__Page__textContent {
    display: none;
}

.react-pdf__Page__annotations {
    height: 0px !important;
}

.react-pdf__Page__canvas {
    width: 100% !important;
    height: 100% !important;
}

.react-pdf__Page {
    min-width: 100% !important;
}

.pdf-page-wrapper .react-pdf__Page {
    box-shadow: none;
}