.alphabetic-tabs .alphabetic-tabs-div ul li {
    list-style: none;
    margin: 3px 0;
}

.alphabetic-tabs .alphabetic-tabs-div ul li a {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F4F4F4;
    margin: 0px 2px;
    border-radius: 6px;
    width: 37px;
    height: 37px;
    font-size: 14px;
    font-weight: 500;
}

.alphabetic-tabs .alphabetic-tabs-div ul li a:hover {
    background-color: #DADEFA;
    color: black;
}

.subject-tabs-div div div .subject-tabs ul li {
    list-style-type: none !important;
    margin: 6px 6px;
    overflow: hidden;
    list-style: none;
    background-color: #F1F2F8;
    padding: 8px 14px;
    border-radius: 6px;
}

.subject-tabs-div .nav-tabs {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.subject-tabs-div .nav-item {
    cursor: pointer;
    padding: 10px;
    border-bottom: 1px solid #ccc;
    background-color: #F1F2F8;
    margin: 0px !important;
    transition: background-color 0.3s, all ease-in-out 0.3s;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    border-left: 10px solid transparent;

}

.subject-tabs-div .nav-item:hover {
    background-color: #ffffff;
    border-left: 10px solid #D743ED;
}

.subject-tabs-div .nav-item.active {
    background-color: #e9ecef;
    border-left: 10px solid #D743ED;
    border-bottom: 1px solid #D743ED;
}


.subject-tabs-div .tab-content {
    margin-top: 10px;
}

.subject-tabs-div .tab-pane {
    display: none;
}

.subject-tabs-div .tab-pane.active {
    display: block;
}

.subject-tabs-div .nav-item a div {
    color: black !important;
}

.subject-tabs-div .nav-item a div:hover {
    color: black !important;
}

.subject-tabs-div div div ul li a i {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.3s;
}

.subject-tabs-div div div ul li a:hover i {
    visibility: visible;
    opacity: 1;
    color: black;
}


.subject-tabs-div .nav-item.active.active a i {
    visibility: visible;
    opacity: 1;
}

.subject-tabs ul li a {
    font-size: 14px;
}

@media only screen and (max-width: 767px) {
    .subject-tabs ul {
        margin-top: 16px;
    }
}