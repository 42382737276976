@import "../../Styles/variables";

.banner-main-wrapper {
  height: auto !important;
  position: relative;
  display: flex;
  justify-content: center;

  .banner-image-pp {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -1;
    filter: brightness(0.8);
  }

  .inside-container-pp {
    width: 100%;
    padding: 100px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .banner-content-pp {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 100%;


    .title-color {
      color: #fff !important;
      font-size: 33px;
      line-height: 5rem;
      font-family: $font-secondary;

      .gradient-title {
        font-weight: $f-semi-bold;
        font-family: $font-primary;
        background: $primary-gradient;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: rgba(255, 255, 255, 0.001);
      }
    }

    // }

    &-subtitle {
      font-size: 1.2rem;
      font-weight: $f-medium;
      line-height: 2.5rem;
    }
  }

  .subtitle-color {
    color: #fff !important;
    font-size: 18px !important;
  }

  .rbt-search {
    width: 100%;
    padding: 3rem 2rem;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    border-top: 1px solid #f1f0f3;
    box-shadow: 0 4px 16px rgba(8, 10, 18, 0.1);
    clip: rect(0px, 200vw, 0, 0px);
    transition: opacity 0.2s linear, clip 0.6s linear, visibility 0s 0.2s;
    transform: translateZ(0);
  }

  .right-form {
    margin: 0px;
    width: 100%;
    padding: 3rem 2rem;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    border-top: 1px solid #f1f0f3;
    box-shadow: 0 4px 16px rgba(8, 10, 18, 0.1);
    clip: rect(0px, 200vw, 0, 0px);
    transition: opacity 0.2s linear, clip 0.6s linear, visibility 0s 0.2s;
    transform: translateZ(0);
  }

  .right-form h3 {
    font-size: 2rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }

  .right-form small {
    font-size: 1.2rem;
    font-weight: 500;
  }

  .right-form Label {
    margin-bottom: 0px;
  }

  .text-wight {
    font-weight: 550;
    font-size: 1.3rem;
    margin-top: 5px;
  }

  .custom-upload-label {
    display: inline-block;
    padding: 5px 12px;
    font-size: 12px;
    font-weight: 500;
    color: #000;
    background-color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;

    span {
      color: #ce45ed;
      font-weight: 600;
      font-size: 14px;
    }
  }

  .upload-icon {
    font-size: 15px;
    vertical-align: middle;
  }

  .inside-form {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .input-wrapper {
    position: relative;
    width: 100%;
  }

  .fs-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    font-size: 1.8rem;
    color: var(--color-secondary);
  }

  .search-ip {
    width: 100%;
    padding: 7px 50px 7px 40px;
    border: 1px solid #e541ed;
    border-radius: 5px;
    font-size: 1rem;
    box-sizing: border-box;
  }

  .search-btn {
    position: absolute;
    padding-top: 4px;
    height: 100%;
    width: 20% !important;
    width: 50px;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    border: none;
    border-radius: 5px !important;
    color: var(--color-white);
    background-size: 300% 100%;
    background-image: linear-gradient(to right,
        var(--color-primary),
        var(--color-secondary),
        var(--color-secondary),
        var(--color-primary));
    border-radius: 3px;
    font-size: 1.1rem;
    cursor: pointer;

    &:hover {
      background-color: inherit;
      background-position: 102% 0;
      transition: all 0.4s ease-in-out;
    }
  }

  .right-form-search-btn2 {
    width: 50% !important;
    height: 50px;
    width: 50px;
    border: none;
    border-radius: 5px !important;
    color: var(--color-white);
    background-size: 300% 100%;
    background-image: linear-gradient(to right,
        var(--color-primary),
        var(--color-secondary),
        var(--color-secondary),
        var(--color-primary));
    border-radius: 3px;
    font-size: 1.4rem;
    cursor: pointer;
    opacity: 0.5;
  }


  .right-form-search-btn {
    width: 50% !important;
    height: 50px;
    width: 50px;
    border: none;
    border-radius: 5px !important;
    color: var(--color-white);
    background-size: 300% 100%;
    background-image: linear-gradient(to right,
        var(--color-primary),
        var(--color-secondary),
        var(--color-secondary),
        var(--color-primary));
    border-radius: 3px;
    font-size: 1.4rem;
    cursor: pointer;


  }

  .right-form-search-btn:hover {
    background-color: inherit;
    background-position: 102% 0;
    transition: all 0.4s ease-in-out;
  }

  .chat-with-us {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }

  .chat-with-us-btn {
    width: 50% !important;
    height: 50px !important;
    border: none;
    border-radius: 5px !important;
    color: var(--color-white);
    background-size: 300% 100%;
    background: linear-gradient(to right, lighten(#147C0C, 10%), #147C0C);
    border-radius: 3px;
    font-size: 1.4rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .chat-with-us-btn span {
    padding-left: 5px;
  }

  .c-padding {
    padding: 5px 5px 5px 48px !important;
  }

  .all-input .form-select {
    height: 42px !important;
  }




  // Mobile Responsiveness
  @media only screen and (max-width: 425px) {
    .banner-content-title-wrapper .title {
      font-size: 1.7rem !important;
      line-height: 2.5rem;
    }

    .banner-content-subtitle {
      font-size: 1.3rem;
    }

    .subtitle-color {
      font-size: 13px !important;
    }

    .search-btn {
      width: 35% !important;
      right: -1px;
      font-size: 1.1rem;
    }

    .search-ip {
      height: 35px;
      padding: 10px 50px 10px 40px;
      border: 1px solid var(--color-secondary);
      font-size: 1rem;
    }

    .inside-container-pp {
      width: 100%;
      padding: 0px 10px;
    }
  }

  @media screen and (min-width:425px) and (max-width:770px) {
    .search-btn {
      width: 30% !important;
      font-size: 1.1rem;
    }

    .search-ip {
      height: 35px;
      padding: 10px 50px 10px 40px;
      font-size: 1rem;
    }

    .inside-container-pp {
      width: 100%;
      padding: 0px 40px;
    }

    .subtitle-color {
      font-size: 13px !important;
    }

  }

  @media only screen and (min-width: 425px) and (max-width: 768px) {
    .banner-content-pp {
      padding: 3rem 1rem;
    }

    .subtitle-color {
      font-size: 13px !important;
    }

    .banner-content-title-wrapper .title .title-color {
      font-size: 4.2rem;
      line-height: 5.1rem;
    }

    .banner-content-subtitle {
      font-size: 1.2rem;
    }

    .search-btn {
      width: 25% !important;
      right: 0px;
      font-size: 1.5rem !important;
    }

    .search-ip {
      height: 42px;
      padding: 8px 0px 8px 40px;
      font-size: 1rem;
    }
  }

  @media only screen and (min-width: 767px) and (max-width: 1023px) {
    .banner-content-title-wrapper .title .title-color {
      // font-size: 6rem;
      // line-height: 6rem;
      font-size: 3.5rem !important;
      line-height: 4.5rem;
    }


    .banner-content-subtitle {
      font-size: 2.2rem;
    }

    .inside-container-pp {
      width: 100%;
      padding: 0px 40px;
    }

  }

  @media only screen and (min-width: 1024px) and (max-width: 1199px) {
    .banner-content-title-wrapper .title {
      font-size: 28px !important;
      line-height: 3.5rem !important;
    }

    .banner-content-subtitle {
      font-size: 1.4rem;
    }
  }
}

.css-yzdvwu-Input{
  padding-left: 30px;
}

.css-ouat2f-Input{
  padding-left: 27px;
}


.css-35k70j-control:hover{
  border-color: #e541ed !important;
}

.css-35k70j-control{
  border-color: #e541ed !important;
  box-shadow: none !important;
}