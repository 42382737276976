@import '../Styles/variables.scss';


.view-popup .modal-dialog {
    max-width: 100%;
    width: 100%;
    margin: 0;
    padding: 0;

}

.view-popup-close-btn {
    margin-right: 0;
    font-size: 16px;
    border: none;
    background: none;
    height: 28px;
    width: 28px;
    border-radius: 50%;
    background: #e541ed;
    color: white;
}

.view-popup-left {
    padding: 3rem;
    // background-color: #F5F5F5;
    height: 100%;
}

.view-popup-left-header {
    text-align: center;
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: 600;
}

.view-popup-pdf-container {
    height: 100%;
    width: 100%;
}

.course-date {
    color: #505050 !important;
    font-weight: 300 !important;
    font-size: 14px !important;
}

.view-popup-pdf {
    margin: 5px 0;
    background-color: #E6E6E6;
    padding: 2rem 4rem 2rem 1rem;
    height: 500px;
    overflow-y: scroll;
}

.view-popup-right-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    padding: 1rem;
    font-size: 1.5rem;
    font-weight: 600;
}

.view-popup-right-header p {
    font-size: 15px;
    color: #CF49ED;
    margin-right: 15px;
}

.view-popup-right-header span {
    font-size: 15px;
}

.view-popup-right-content p {
    font-size: 13px;
    font-weight: 400;
}

.view-popup-right-content {
    padding: 2rem;
    line-height: 25px;
}


.view-popup-price {
    margin: 10px 0;
    font-size: 10px !important;
}

.view-popup-price span {
    font-size: 20px;
    font-weight: 600;
    color: #CE45ED;
    margin-right: 8px;
}

@media screen and (max-width: 1024px) {
    .view-popup-pdf {
        height: 340px;
    }
}

@media screen and (max-width: 768px) {
    .view-popup-pdf {
        height: 250px;
    }

    .view-popup-right-content p {
        font-size: 10px;
        font-weight: 400;
    }

    .view-popup-right-content {
        padding: 2rem;
        line-height: 15px;
    }

    .view-popup-right-content h2 {
        font-size: 12px;
    }
}

@media screen and (max-width: 600px) {

    .view-popup-left {
        padding: 1.5rem;
        background-color: #F5F5F5;
        height: 100%;
        width: 95%;
    }

}