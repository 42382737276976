@import "../Styles/variables";

.step-header {
  font-size: 3rem;
  font-weight: 600;
  font-family: "Poppins";
}

.step-sub-header {
  padding-top: 3rem;
  font-size: 2.5rem;
  font-weight: 600;
}

.stap-form-1 {
  padding-top: 1rem;
}

// .form-label {
//   font-size: 2rem;
// }

.form-check-input {
  margin-top: 0%;
  width: 3rem;
  height: 3rem;
  border: 1px solid $color-primary !important;
}

.form-check-input:focus {
  box-shadow: none !important;
}

.form-check-input:checked[type="radio"] {
  --bs-form-check-bg-image: url("../Assets/images/check.svg") !important;
}

.form-check-input:checked {
  background: linear-gradient(180deg, #e541ed 0%, #5956e9 100%),
    linear-gradient(0deg, #e5e5fe, #e5e5fe) !important;
}

.question-textarea {
  height: 25rem;
  font-size: 2rem;
  border-radius: 1.5rem;
}

.form-select {
  // font-size: 2.5rem;

  &:focus {
    box-shadow: none !important;
  }
}

.type-select,
.type-select:focus-visible {
  border: 2px solid #e541ed;
}

// .card {
//   width: 50% !important;
//   height: 100% !important;
//   background-color: transparent !important;
// }

.card::before {
  background-color: transparent !important;
}

.card-title {
  font-size: 2.5rem !important;
}

.card-body {
  width: 100%;
}

.card-text {
  font-size: 2rem !important;
}

.librarybanner-title {
  font-size: 3.2rem;
  line-height: 5rem;
  color: #06241b;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
}

// above Delete
.banner-main-wrapper {
  height: calc(75vh - 80px);
  position: relative;
  display: flex;
  justify-content: center;

  .banner-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -1;
  }

  .banner-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 80px 10px;
    width: 75rem;

    &-title-wrapper {
      .title {
        font-size: 3.6rem;
        line-height: 5rem;
        color: $color-primary-900;
        font-family: $font-secondary;

        .gradient-title {
          font-weight: $f-semi-bold;
          ;
          font-family: $font-primary;
          background: $primary-gradient;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: rgba(255, 255, 255, 0.001);
        }
      }
    }

    &-subtitle {
      font-size: 1.5rem;
      font-weight: $f-medium;
      line-height: 2.5rem;
      color: $color-gray-500;
    }

    &-available-text {
      font-size: 3rem;
      line-height: 7.2rem;
      color: $color-primary-900;
      font-weight: $f-semi-bold;
      ;
    }
  }

  // .rbt-search-dropdown {
  // position: absolute;
  // margin: auto;
  // width: 80%;
  // top: 100%;
  // left: 0;
  // right: 0;
  // background: rgba(255, 255, 255, 0.5);
  // border-top: 1px solid #f1f0f3;
  // box-shadow: 0 4px 16px rgba(8, 10, 18, 0.1);
  // z-index: 104;
  // // visibility: hidden;
  // clip: rect(0px, 200vw, 0, 0px);
  // // opacity: 0;
  // transition: opacity 0.2s linear, clip 0.6s linear, visibility 0s 0.2s;
  // transform: translateZ(0);
  // }

  .rbt-search {
    width: 100%;
    padding: 3rem 2rem;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    border-top: 1px solid #f1f0f3;
    box-shadow: 0 4px 16px rgba(8, 10, 18, 0.1);
    clip: rect(0px, 200vw, 0, 0px);
    transition: opacity 0.2s linear, clip 0.6s linear, visibility 0s 0.2s;
    transform: translateZ(0);
  }

  .text-wight {
    font-weight: 550;
    font-size: 1.3rem;
    margin-top: 5px;
  }

  .custom-upload-label {
    display: inline-block;
    padding: 5px 12px;
    font-size: 12px;
    font-weight: 500;
    color: #000;
    background-color: #fff;
    // border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .custom-upload-label span {
    color: #ce45ed;
    font-weight: 600;
    font-size: 14px;
  }


  .upload-icon {
    font-size: 15px;
    vertical-align: middle;
  }

  .inside-form {
    display: flex;
    // justify-content: center;
    align-items: center;
    /* Centers elements vertically */
    width: 100%;
  }

  .input-wrapper {
    position: relative;
    width: 100%;
    /* Adjust to fit container */
    // max-width: 600px; /* Optional: set a max width */ 
  }

  .fs-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    /* Adjust spacing from the left edge */
    transform: translateY(-50%);
    /* Centers the icon vertically */
    font-size: 1.8rem;
    /* Adjust icon size */
    color: var(--color-secondary);
  }

  .search-ip {
    width: 100%;
    padding: 7px 50px 7px 40px;
    /* Left padding for icon, right padding for button */
    border: 1px solid #e541ed;
    /* Optional: Add border */
    border-radius: 5px;
    font-size: 1rem;
    box-sizing: border-box;
    /* Prevent padding from affecting total width */
  }

  .search-btn {
    position: absolute;
    padding-top: 4px;
    height: 100%;
    width: 20% !important;
    width: 50px;
    /* Adjust button width */
    top: 50%;
    right: 0px;
    /* Adjust spacing from the right edge */
    transform: translateY(-50%);
    border: none;
    border-radius: 5px !important;
    color: var(--color-white);
    background-size: 300% 100%;
    background-image: linear-gradient(to right,
        var(--color-primary),
        var(--color-secondary),
        var(--color-secondary),
        var(--color-primary));
    border-radius: 3px;
    /* Smooth edges */
    font-size: 1.5rem;
    cursor: pointer;
  }

  .search-btn:hover {
    background-color: inherit;
    background-position: 102% 0;
    transition: all 0.4s ease-in-out;
  }


  @media only screen and (max-width: 425px) {

    .title h1 {
      // padding-top: 10px;
      line-height: 30px;
    }

    /* Styles for mobile phones */
    .banner-content-title-wrapper .title {
      // font-size: 3rem;
      // line-height: 3rem;
      font-size: 1.7rem !important;
      line-height: 2.5rem;

    }

    .librarybanner-title {
      font-size: 17px;
      font-weight: 600;
      line-height: 3rem;
    }

    .title strong {
      font-weight: 600;
      font-size: 1.6rem !important;
    }

    .banner-content-subtitle {
      font-size: 1.3rem;
    }

    .banner-content-available-text {
      font-size: 2rem;
    }

    .search-btn {
      position: absolute;
      padding-top: 4px;
      height: 100%;
      // width: 20% !important;
      width: 35% !important;
      /* Adjust button width */
      top: 50%;
      right: -1px;
      /* Adjust spacing from the right edge */
      transform: translateY(-50%);
      border: none;
      border-radius: 5px !important;
      color: var(--color-white);
      background-size: 300% 100%;
      background-image: linear-gradient(to right,
          var(--color-primary),
          var(--color-secondary),
          var(--color-secondary),
          var(--color-primary));
      font-size: 1.1rem;
      cursor: pointer;
    }

    .search-ip {
      width: 100%;
      height: 35px;
      padding: 10px 50px 10px 40px;
      /* Left padding for icon, right padding for button */
      border: 1px solid var(--color-secondary);
      /* Optional: Add border */
      border-radius: 5px;
      font-size: 1rem;
      box-sizing: border-box;
      /* Prevent padding from affecting total width */
    }

  }

  @media screen and (min-width:425px) and (max-width:770px) {
    .search-btn {
      position: absolute;
      padding: 4px 0px 0px 0px;
      height: 100%;
      // width: 20% !important;
      width: 30% !important;
      /* Adjust button width */
      top: 50%;
      right: 0px;
      /* Adjust spacing from the right edge */
      transform: translateY(-50%);
      border: none;
      border-radius: 5px !important;
      color: var(--color-white);
      background-size: 300% 100%;
      background-image: linear-gradient(to right,
          var(--color-primary),
          var(--color-secondary),
          var(--color-secondary),
          var(--color-primary));
      font-size: 1.1rem;
      cursor: pointer;
    }

    .search-ip {
      width: 100%;
      height: 35px;
      padding: 10px 50px 10px 40px;
      /* Left padding for icon, right padding for button */
      border: 1px solid var(--color-secondary);
      /* Optional: Add border */
      border-radius: 5px;
      font-size: 1rem;
      box-sizing: border-box;
      /* Prevent padding from affecting total width */
    }
  }

  @media only screen and (min-width: 425px) and (max-width: 768px) {

    .banner-content {
      padding: 3rem 1rem;
    }

    .banner-content-title-wrapper .title {
      font-size: 4.2rem;
      line-height: 5.1rem;
    }

    .librarybanner-title {
      font-size: 17px;
      font-weight: 600;
      line-height: 3rem;
    }

    .banner-content-subtitle {
      font-size: 1.2rem;
    }

    .banner-content-available-text {
      font-size: 3rem;
    }

    .title strong {
      font-weight: 600;
      font-size: 1.6rem !important;
    }

    button {
      padding: 0.7rem 4.3rem;
    }

    .banner-main-wrapper .search-btn {
      position: absolute;
      padding: 2px 0px 0px 0px !important;
      height: 100%;
      width: 25% !important;
      top: 50%;
      right: -65px;
      transform: translateY(-50%);
      border: none;
      border-radius: 5px !important;
      color: var(--color-white);
      background-size: 300% 100%;
      background-image: linear-gradient(to right, var(--color-primary), var(--color-secondary), var(--color-secondary), var(--color-primary));
      font-size: 1.5rem !important;
      cursor: pointer;
    }

    .search-ip {
      width: 100%;
      height: 35px;
      padding: 8px 0px 8px 40px;
      /* Left padding for icon, right padding for button */
      border: 1px solid var(--color-secondary);
      /* Optional: Add border */
      border-radius: 5px;
      font-size: 1rem;
      box-sizing: border-box;
      /* Prevent padding from affecting total width */
    }

  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {

    .banner-content-title-wrapper .title {
      font-size: 6rem;
      line-height: 6rem;
    }

    .banner-content-subtitle {
      font-size: 2.2rem;
    }

    button {
      padding: 0.8rem 4.5rem;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1199px) {

    .banner-content-title-wrapper .title {
      font-size: 6.5rem;
      line-height: 6.5rem;
    }

    .banner-content-subtitle {
      font-size: 2.4rem;
    }
  }
}


.title strong {
  font-weight: 600;
}



.img-fluid-home {
  max-width: 70%;
  height: auto;
  display: block;
  margin: 0 auto;
}

@media (max-width: 767.98px) {
  .img-fluid-home {
    max-width: 100%;
  }
}

.title.mt-4 {
  margin-top: 10rem !important;
}

.css-b62m3t-container {
  width: 100%;
}

.css-1fdsijx-ValueContainer {
  text-align: start;
}

.css-1s2u09g-singleValue {
  font-size: 16px;
}

.css-yzdvwu-Input {
  padding-left: 30px;
}

.css-ouat2f-Input {
  padding-left: 27px;
}

.css-35k70j-control {
  padding: 4px 0px 4px 30px
}

.css-35k70j-control:hover {
  border-color: #e541ed !important;
}

.css-35k70j-control {
  border-color: #e541ed !important;
  box-shadow: none !important;
}

.css-o9youv-placeholder {
  margin-left: 30px !important;
}

.css-1dimb5e-singleValue {
  margin-left: 30px !important;
  font-size: 16px;
}

// all subjects banner
.all-sub-banner-title {
  font-size: 22px !important;
  line-height: 60px;
  letter-spacing: 1px;
}

.all-sub-banner-subtittle {
  font-size: 14px !important;
  line-height: 24px;
}

@media (max-width: 768px) {
  .all-sub-banner-title {
    font-size: 21px !important;
  }

  .all-sub-banner-subtittle {
    font-size: 13px !important;
    line-height: 22px;
  }
}

@media (max-width: 425px) {
  .all-sub-banner-title {
    font-size: 18px !important;
    line-height: 30px;
  }

  .all-sub-banner-subtittle {
    font-size: 13px !important;
    margin-top: 12px;
    line-height: 22px;
  }
}