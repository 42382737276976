@import "../Styles/variables";

.universities-container {
  background-size: cover;
  background-position: center;
  text-align: center;
  color: white;
  margin-top: 4rem;
  padding: 4rem 2rem;

  .universities-steps-container {
    padding: 2rem;
    border-radius: 1rem;
    margin: auto;
    max-width: 800px;

    .universities-title {
      font-size: 2rem;
      font-weight: bold;
      margin-bottom: 1rem;
      color: #2f2f2f;
    }

    .universities-description {
      font-size: 1.2rem;
      margin-top: 1rem;
      color: #6d737a;
    }
  }

  .universities-list {
    // margin-top: 2rem;
    color: #2f2f2f;
    padding-bottom: 15px;

    .row {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      .universitie-table {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 300px;
        margin: 0.5rem;

        .box {
          background: rgba(255, 255, 255, 0.8);
          color: #333;
          padding: 1rem;
          width: 100%;
          text-align: center;
          border: 1px solid #b0aeff;
          margin-bottom: 1rem;
          font-weight: 600;
          letter-spacing: 0.5px;
          font-size: 11px;
        }

        .box.many-more {
          border: none;
          font-weight: 500;
        }
      }
    }
  }

  .many-more-btn {
    text-decoration: none;
    color: #2f2f2f;
  }

  .box:hover {
    border: 1px solid #e541ed !important;
    color: #e541ed !important;
  }

}



@media (min-width: 768px) {
  .universities-container {
    padding: 6rem 3rem;

    .universities-steps-container {
      padding: 3rem;

      .universities-title {
        font-size: 2.5rem;
      }

      .universities-description {
        font-size: 1.5rem;
      }
    }

    .universities-list .row {
      .universitie-table .box {
        margin: 1rem;
      }
    }
  }
}

@media (min-width: 992px) {
  .universities-container {
    // padding: 0 30rem 3rem;

    .universities-steps-container {
      padding: 4rem;

      .universities-title {
        font-size: 2.2rem;
      }

      .universities-description {
        font-size: 1.5rem;
      }
    }

    .universities-list .row {
      .universitie-table .box {
        margin: 1.5rem;
      }
    }
  }
}