.step {
  color: white;
  width: 30px;
  height: 30px;
  font-size: 18px;
  background: linear-gradient(180deg, #E541ED 0%, #5956E9 100%),linear-gradient(0deg, #E5E5FE, #E5E5FE);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.step-text {
  width: 300%;
  position: absolute;
  top: 80%;
  font-size: 18px;
  margin-top: 20px;
  color: #535252;
}




.step.completed {
  background-color: indigo;
}

@media screen and (max-width: 500px) {
  
  .step{
    width: 25px;
    height: 25px;
    font-size: 12px;
  }

  .step-text {
    font-size: 14px;
  }
  
}
