.thank-you-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px 0px;
}

.thank-you-container .course-name {
    color: #6A57E9;
    // font-size: 32px;
    font-size: 24px;
    font-weight: 600;
}

.thank-you-container .college-name {
    // font-size: 20px;
    font-size: 16px;
    font-weight: 500;
}

.thank-you-container .dec {
    font-size: 14px;
    color: rgb(133, 133, 133);
}

.order-time {
    color: #D544ED;
    font-size: 24px;
    font-weight: 500;
}

.pdf-doc-container {
    margin: 20px auto;
}

.pdf-doc-container img {
    width: 70px;
    height: 70px;
    object-fit: cover;
    filter: blur(2px);
}

.thank-you-container hr {
    width: 50%;
    margin: 20px 0px;
}

.progress-bar-line {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 40px 0px;
}

.custom-w-progress-line{
    width: 75%;
}

.custom-w-progress-line .steps-css{
    font-size: 16px;
    line-height: 20px;
}

.order-contact {
    background-color: #F1F2F8;
    border-radius: 20px;
}

.all-btn-container {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin: 20px 0px 0px 0px;
}

.email-w-us {
    padding: 10px;
    font-size: 15px;
    color: white;
    border: none;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6554E9;
    font-weight: 500;
}

.chat-w-whatsapp {
    padding: 10px;
    font-size: 15px;
    color: white;
    border: none;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #147C0C;
    font-weight: 500;
}

.email-w-us i,
.chat-w-whatsapp i {
    font-size: 25px;
    padding-right: 5px;
}

@media (max-width:425px) {
    .all-btn-container {
        display: flex;
        flex-direction: column;
    }
    .custom-w-progress-line{
        width: 90%;
    }
    .custom-w-progress-line .steps-css{
        font-size: 12px !important;
    }
    
}

@media (max-width:768px) {
    .thank-you-container .course-name {
        font-size: 26px;
    }

    .thank-you-container .college-name {
        font-size: 16px;
    }

    .thank-you-container .dec {
        font-size: 12px;
    }

    .thank-you-container .order-time {
        font-size: 16px;
    }

    .till-contact {
        font-size: 14px;
        line-height: 25px;
    }

    .thank-you-container hr {
        width: 80%;
        margin: 20px 0px;
    }

    .order-time {
        font-size: 17px;
    }

    .custom-w-progress-line .steps-css{
        font-size: 14px ;
    }
    
}