.certificate {
    background-color: #FBFBFB;

    .certificate-image {
        position: relative;
    }

    .certificate-image img {
        width: 500px;
    }

    .certified1 img,
    .certified2 img,
    .certified3 img,
    .certified4 img {
        width: 80px;
    }

    .certified1 {
        text-align: end;
        position: absolute;
        top: 20px;
        left: 210px;
    }

    .certified2 {
        text-align: start;
        position: absolute;
        top: 20px;
        right: 210px;
    }

    .certified3 {
        text-align: end;
        position: absolute;
        bottom: 20px;
        left: 210px;
    }

    .certified4 {
        text-align: start;
        position: absolute;
        bottom: 20px;
        right: 210px;
    }
}

@media (min-width:769px) and (max-width:1200px) {
    .certificate-image img {
        width: 400px;
    }

    .certified1 {
        text-align: end;
        position: absolute;
        top: 25px;
        left: 120px !important;
    }

    .certified2 {
        text-align: start;
        position: absolute;
        top: 25px;
        right: 120px !important;
    }

    .certified3 {
        text-align: end;
        position: absolute;
        bottom: 25px;
        left: 120px !important;
    }

    .certified4 {
        text-align: start;
        position: absolute;
        bottom: 25px;
        right: 120px !important;
    }

}

@media (max-width: 768px) {
    .certificate {
        background-color: #FBFBFB;

        .certificate-image {
            position: relative;
        }

        .certificate-image img {
            width: 400px;
        }

        .certified1 img,
        .certified2 img,
        .certified3 img,
        .certified4 img {
            width: 70px;
        }

        .certified1 {
            text-align: end;
            position: absolute;
            top: 25px;
            left: 70px;
        }

        .certified2 {
            text-align: start;
            position: absolute;
            top: 25px;
            right: 70px;
        }

        .certified3 {
            text-align: end;
            position: absolute;
            bottom: 25px;
            left: 70px;
        }

        .certified4 {
            text-align: start;
            position: absolute;
            bottom: 25px;
            right: 70px;
        }
    }
}

@media (max-width: 425px) {
    .certificate {
        background-color: #FBFBFB;

        .certificate-image {
            position: relative;
        }

        .certificate-image img {
            width: 220px;
        }

        .certified1 img,
        .certified2 img,
        .certified3 img,
        .certified4 img {
            width: 50px;
        }

        .certified1 {
            text-align: end;
            position: absolute;
            top: 10px;
            left: 30px;
        }

        .certified2 {
            text-align: start;
            position: absolute;
            top: 10px;
            right: 30px;
        }

        .certified3 {
            text-align: end;
            position: absolute;
            bottom: 10px;
            left: 30px;
        }

        .certified4 {
            text-align: start;
            position: absolute;
            bottom: 10px;
            right:30px;
        }
    }
}

@media (max-width: 320px) {
    .certificate {
        background-color: #FBFBFB;

        .certificate-image {
            position: relative;
        }

        .certificate-image img {
            width: 200px;
        }

        .certified1 img,
        .certified2 img,
        .certified3 img,
        .certified4 img {
            width: 40px;
        }

        .certified1 {
            text-align: end;
            position: absolute;
            top: 10px;
            left: 10px;
        }

        .certified2 {
            text-align: start;
            position: absolute;
            top: 10px;
            right: 10px;
        }

        .certified3 {
            text-align: end;
            position: absolute;
            bottom: 10px;
            left: 10px;
        }

        .certified4 {
            text-align: start;
            position: absolute;
            bottom: 10px;
            right: 10px;
        }
    }
}