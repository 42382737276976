.back-link {
    cursor: pointer;
    color: rgb(71, 39, 195);
    font-size: 1.6rem;
  }
  
  .que-question-card {
    background-color: #f1f1ff !important;
    display: flex;
    flex-direction: column;
    border-radius: 2rem;
    border: 1px solid #dbdbdb;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    padding: 1rem 2.8rem 1rem;
    font-size: 1.4rem;
  }
  
  .answer-card {
    background-color: #fff1fe !important;
    display: flex;
    flex-direction: column;
    border-radius: 2rem;
    border: 1px solid #dbdbdb;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    padding: 1rem 2.8rem 1rem;
    font-size: 1.6rem;
  }
  
  .add-transcript {
    width: 124px;
    padding: 8px;
    font-size: 12px;
    background-color: #d033f9 !important;
    border: none;
  }
  
  .add-transcrip:hover {
    background-color: #d033f9 !important;
  }

  @media screen and (max-width: 430px) {
    .search-sidebar{
        margin-left: 3rem;
        margin-right: 3rem;
    }
    
  }