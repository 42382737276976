@import "../../Styles/variables";


// above Delete
.banner-main-wrapper-course {
  height: 70%;
  position: relative;
  display: flex;
  justify-content: center;

  .banner-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -1;
  }

  .banner-content-pc {
    &-title-wrapper {

      .title-course-banner {
        font-size: 3.5rem;
      }

      .title {
        font-size: 3.6rem;
        line-height: 5rem;
        color: $color-primary-900;
        font-family: $font-secondary;

        .gradient-title {
          font-weight: $f-semi-bold;
          ;
          font-family: $font-primary;
          background: $primary-gradient;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: rgba(255, 255, 255, 0.001);
        }
      }
    }

    &-subtitle {
      font-weight: $f-medium;
      line-height: 2.5rem;
      color: $color-gray-500;
    }

    &-available-text {
      font-size: 3rem;
      line-height: 7.2rem;
      color: $color-primary-900;
      font-weight: $f-semi-bold;
      ;
    }
  }

  .course-subtitle {
    font-size: 1.5rem;
    padding: 0 25px;
  }


  .course-section-left-text {
    font-size: 1.2rem;
    color: #737373;
  }

  .course-banner-review {
    color: #c71afb;
  }

  @media only screen and (max-width: 425px) {

    .title h1 {
      // padding-top: 10px;
      line-height: 30px;
    }

    /* Styles for mobile phones */
    .banner-content-title-wrapper .title {
      // font-size: 3rem;
      // line-height: 3rem;
      font-size: 1.7rem !important;
      line-height: 2.5rem;

    }

    .librarybanner-title {
      font-size: 17px;
      font-weight: 600;
      line-height: 3rem;
    }

    .title strong {
      font-weight: 600;
      font-size: 1.6rem !important;
    }

    .banner-content-subtitle {
      font-size: 1.3rem;
    }

    .banner-content-available-text {
      font-size: 2rem;
    }


    .text-wight-que-banner {
      font-weight: 550;
      font-size: 1rem;
      margin-top: 5px;
    }



  }



  @media only screen and (min-width:602px) and (max-width: 768px) {

    .banner-content-pc {
      padding: 3rem 1rem;
    }

    .banner-content-title-wrapper .title {
      font-size: 4.2rem;
      line-height: 5.1rem;
    }

    .librarybanner-title {
      font-size: 17px;
      font-weight: 600;
      line-height: 3rem;
    }

    .banner-content-subtitle {
      font-size: 1.2rem;
    }

    .banner-content-available-text {
      font-size: 3rem;
    }

    .title strong {
      font-weight: 600;
      font-size: 3.6rem !important;
    }




  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {

    .banner-content-title-wrapper .title {
      font-size: 6rem;
      line-height: 6rem;
    }

    .banner-content-subtitle {
      font-size: 2.2rem;
    }


  }

  @media only screen and (min-width: 1024px) and (max-width: 1199px) {

    .banner-content-title-wrapper .title {
      font-size: 4.5rem;
      line-height: 6.5rem;
    }

    .banner-content-subtitle {
      font-size: 2.1rem;
    }
  }
}


.title strong {
  font-weight: 600;
}


.img-fluid-home {
  max-width: 70%;
  height: auto;
  display: block;
  margin: 0 auto;
}

@media (max-width: 600px) {
  .img-fluid-home {
    max-width: 100%;
  }

}

.title.mt-4 {
  margin-top: 10rem !important;
}

.banner-main-wrapper-course .banner-content-pc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 10px;
  width: 85rem;
}

@media (max-width: 767px) {
  .banner-main-wrapper-course .banner-content-pc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 35px 10px !important;
    width: 85rem;
  }
}

.university-url:hover {
  color: #c81afb;
}