.alphabetic-tabs .alphabetic-tabs-div ul li {
    list-style: none;
    margin: 3px 0;
}

.alphabetic-tabs .alphabetic-tabs-div ul li a {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F4F4F4;
    margin: 0px 2px;
    border-radius: 6px;
    width: 37px;
    height: 37px;
    font-size: 14px;
    font-weight: 500;
}

.alphabetic-tabs .alphabetic-tabs-div ul li a:hover {
    background-color: #DADEFA;
    color: black;
}

.all-alphabet {
    text-align: start;
    margin: 20px 0px;
}

.all-alphabet div .university-name {
    padding-bottom: 0px;
    font-size: 15px;
    color: #7262EB;
    font-weight: 500;
}

.all-alphabet div .university-name span {
    color: #858585;
}

.single-alphabet {
    color: #D743ED;
    margin: 10px 0px;
}


.all-courses {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.all-courses .all-alphabet {
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    text-align: start;
}

.all-alphabet .uni-name-container hr {
    height: 2px;
    border-width: 0;
    background-color: rgb(174, 174, 174);
    position: relative;
    overflow: hidden;
}

.all-alphabet .uni-name-container:hover hr {
    background-color: transparent; 
}

.all-alphabet .uni-name-container:hover hr::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: #D743ED; 
    animation: flow 0.5s linear forwards; 
}

@keyframes flow {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}


@media (max-width: 767px) {
    .all-courses .all-alphabet {
        width: 86% !important;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        text-align: start;
    }
}