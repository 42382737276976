@import '../../styles/variables.scss';

.main-container{
    margin : 30px auto;
    padding: 15px 5px;
    background-color: rgb(247, 247, 247);


.inside-container{
    margin: 20px;
    background-color: #ffffff;

    .pn-left-part h2 , .pn-right-part h2{
        font-size: 25px ;
        font-weight: 500;
    }


    .cart-item{
        width: 125%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
    }

    .cart-item img{
        width: 80%;
        height: auto;
    }

    .pn-que-course-name {
        font-size: 10px;
      }
      
    .pn-que-course-name span {
        color: #6754E9;
        font-weight: 600;
      }

      .pn-que-course-sub1 {
        font-size: 15px;
      }
      
      .pn-que-course-sub2 {
        font-size: 12px;
        line-height: 20px;
      }

      .pn-que-bank-text {
        color: #afabab;
      }

      .pn-que-course-name {
        font-size: 12px;
        color: #6b6868;
      }

      .inside-form > div{
        margin: 10px 0px;
      }

      .inside-form Label{
        font-size: 15px;
        font-weight: 600;
     } 

     .inside-form input{
        width: 100%;
        height: 40px;
        padding: 10px;
        
        border: 1px solid #afabab;
        border-radius: 5px;
     }

     .inside-form select{
        width: 100%;
        height: 40px;
        padding: 10px;
       font-size: 15px;
        border: 1px solid #afabab;
        border-radius: 5px;
     }

     .inside-form option{
        font-size: 15px !important;
     } 
}


  .pn-progress-step{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    padding: 10px 0px;
    
  }

  .pn-progress-step p{
    font-size: 16px;
    font-weight: 500;
  }

  .progress-circle{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #e541ed ;
    color: #ffffff;
    font-size: 10px;
    padding-top: 1px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pn-pg-main{
    margin: 10px 0px;
    border-bottom: 3px solid;
    border-image: linear-gradient(to right, #e541ed 42%, #f1ecf2 35%);
    border-image-slice: 1;
  }

  .pn-inactive .progress-circle{
    background-color: #e7d1e8 !important;
  }

  .pn-inactive{
    color: #cbcaca !important;
  }



    @media only screen and (min-width: 1200px) {

    width: 80%;

    .pn-left-part{
        padding:10px 40px;
        border-right: 2px solid #afabab;
    }

    .pn-right-part{
        padding:10px 40px;
      }

      .pn-purchase, .pn-views {
        color: #afabab ;
        font-size: 13px !important;
      }

    
        
     .inside-form Label{
        font-size: 15px !important;
        font-weight: 600;
     } 

     .inside-form input{
        width: 100%;
        height: 40px;
        padding: 10px;
        
        border: 1px solid #afabab;
        border-radius: 5px;
     }

     .inside-form select{
        width: 100%;
        height: 40px;
        padding: 10px;
       font-size: 15px;
        border: 1px solid #afabab;
        border-radius: 5px;
     }

     .inside-form option{
        font-size: 15px !important;
     }

    }

    @media only screen and (min-width: 992px) {
        width: 90%;

        .cart-item img{
            width: 100% !important;
        }

        .pn-pg-main{
          margin: 10px 0px;
          border-bottom: 3px solid;
          border-image: linear-gradient(to right, #e541ed 40%, #f1ecf2 35%);
          border-image-slice: 1;
        }

        .pn-left-part{
            padding:10px 20px;
            border-right: 1px solid #afabab;
        }

        .cart-item{
            padding: 10px 15px;
        }

        .pn-purchase, .pn-views {
            color: #afabab;
            font-size: 7px;
          }
    
        .pn-right-part{
            padding:10px 20px;
        }


        .inside-form > div{
            margin: 10px 0px;
          }
            
         .inside-form Label{
            font-size: 14px !important;
            font-weight: 600;
         } 
    
         .inside-form input{
            width: 100%;
            height: 40px;
            padding: 10px;
            
            border: 1px solid #afabab;
            border-radius: 5px;
         }
    
         .inside-form select{
            width: 100%;
            height: 40px;
            padding: 10px;
           font-size: 14px;
            border: 1px solid #afabab;
            border-radius: 5px;
         }
    
         .inside-form option{
            font-size: 14px !important;
         }
    
    }

    @media screen and (min-width: 768px) {

        .pn-left-part{
            padding:10px 20px;
            margin-bottom: 10px;
                 }

        .pn-right-part{
            padding:10px 20px;
        }
    }

    @media screen and (max-width: 768px) {
        .pn-right-part{
            border-top: 1px solid #afabab;
        }
    }

    @media screen and (max-width: 600px) {
       .cart-item{
            width: 100% !important;
        }

        .cart-item img{
            width: 50% !important;
        }

       .progress-circle {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          background-color: #e541ed;
          color: #ffffff;
          font-size: 8px;
          padding-top: 1px;
          display: flex;
          justify-content: center;
          align-items: center;
      }

      .pn-progress-step p{
        font-size: 7px;
        font-weight: 500;
      }
    }

}