.header-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.text-img-adj {
  padding: 10px !important;
}

/* Main submenu styles */
.submenu {
  // position: absolute;
  background-color: #fff;
  // box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  min-width: 200px;
}

/* Position submenu items relatively to allow proper child positioning */
.submenu li.has-dropdown {
  position: relative;
}

/* Right-side submenu styles */
.submenu-right {
  display: none;
  width: 250px;
  max-height: 250px;
  overflow-y: auto;
  position: absolute;
  left:240px;
  top: 80px; /* Align with the top of the parent submenu item */
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
  min-width: 200px;
  padding: 12px 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

/* Make sure submenu-right shows only on hover of parent */
.submenu li.has-dropdown:hover > .submenu-right {
  display: block;
}

.submenu-right::-webkit-scrollbar {
  width: 8px; /* Set the scrollbar width */
}

.submenu-right::-webkit-scrollbar-track {
  background: #f0f0f0; /* Track color */
  border-radius: 10px;
}

.submenu-right::-webkit-scrollbar-thumb {
  background: #c945ec; /* Pink color for the scrollbar */
  border-radius: 10px;
  border: 2px solid #f0f0f0; /* Adds spacing inside scrollbar */
}

.submenu-right::-webkit-scrollbar-thumb:hover {
  background: #8a17aa; /* Darker pink on hover */
}


/* Ensure submenu items don't wrap */
.submenu-right li a {
  font-weight: 500;
  padding: 10px 10px;
  font-size: 14px;
  display: block;
  color: var(--color-body);
  margin: 0 10px;
  border-radius: 3px;
}

.submenu-right li a:hover {
  color: #c945ec !important;
  background: var(--black-opacity);
}

/* Mobile responsiveness */
@media (max-width: 767px) {
  .submenu-right {
    position: static;
    box-shadow: none;
    display: none;
  }
  
  .submenu li.has-dropdown:hover > .submenu-right {
    display: none;
  }
  
  .submenu li.has-dropdown.active > .submenu-right {
    display: block;
  }
  
  .Whatsapp-any-query {
    font-size: 12px;
  }
}



.new-submenu{
  padding: 0;
  max-width: 100%;
  list-style: none;
  padding-left: 14px;
}