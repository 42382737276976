@import '../../styles/variables.scss';

.course-benefits {
  display: flex;
  justify-content: center;
  margin: 7rem 0 0 0;
  padding: 20px;
  background-color: #f1f2f8;

  .course-benefits__container {
    width: 80%;
    padding: 20px;
  }

  .course-benefits__title {
    font-size: 20px;
    margin: 30px 0px;

  }


  .course-benefits__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    padding: 20px;
  }

  .course-benefits__card img {
    width: 70px;
    height: 60px;
    margin-bottom: 10px;
  }

  .course-benefits__card h3 {
    font-size: 15px;
    font-weight: $f-medium;
    margin-bottom: 10px;
  }


  .course-benefits__card p {
    font-size: 12px;
  }

  @media screen and (max-width: 768px) {
    .course-benefits__container {
      width: 90%;
      padding: 20px;
    }
  }

  @media screen and (max-width: 425px) {
    .course-benefits__container {
      width: 100%;
      padding: 0px;
    }

    .course-benefits .course-benefits__card{
      padding: 0px;
    }
  }

}