.cursor-pointer {
    cursor: pointer;
}

.filter-university {
    margin: 25px auto;

    .filter-acc {

        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid #dfdfdf !important;
        margin-top: 0;
        margin-bottom: 0rem;
    }
}

@media screen and (max-width: 768px) {
    .mobile-animation {
        animation: slideIn 0.5s forwards ease-in;
    }
}

@keyframes slideIn {
    from {
        transform: translateX(-100%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
}
