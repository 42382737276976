.doc-preview-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow-y: auto;
  height: auto;
}

.both-pages{
  height: 70vh;
}

/* Page 1 */
.page-one {

  height: 70vh; /* Full page height */
  position: relative;
  border: 2px solid #ccc;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: #f4f4f4;
}

.page-one .company-logo {
 position: absolute;
 top: 10px;
 left: 10px;
}

.page-two {

  height: 70vh; /* Full page height */
  position: relative;
  border: 2px solid #ccc;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  // background-color: #f4f4f4;
}

.course-name {
  text-align: start;
  margin-top: 10px;
  margin-bottom: 10px;
}

.course-name p {
  font-size: 18px;
  color: #666666;
  margin: 4px 0px;
}

@media (max-width: 768px) {


  .course-name p {
    font-size: 14px; 
  }

  .both-pages{
    height: 80vh;
  }
  

  .page-one {
    height: 80vh;
  }


 
  /* Page 2: Adjust button size */
  .page-two .buy-now-btn {
    font-size: 1.2rem; 
    padding: 10px 20px; 
  }
}

@media (max-width: 480px) {


  .course-name p {
    font-size: 12px;
  }

  .page-two .buy-now-btn {
    font-size: 1rem; 
    padding: 8px 16px; 
  }
}
