.ptb--100 {
    padding: 100px 0 !important;
}

.bg-gradient-1 {
    background: linear-gradient(rgb(255, 255, 255) 0%, rgb(239, 241, 255) 100%) !important;
}

.rbt-breadcrumb-default .title {
    color: var(--color-heading);
    margin-bottom: 5px;
    font-size: 40px;
}

.custom-icon {
    background: linear-gradient(90deg, #5956e9, #e541ed);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.001);
    font-weight: var(--f-semi-bold);
    margin-right: 10px;
}


.page-list {
    padding: 0;
    margin: 0;
    list-style: none;
}

.page-list li {
    display: inline-block;
    font-size: 16px;
    color: #666666;
    margin: 0;
}


.plan-offer {
    list-style: none;
    font-size: 1.4rem;
    line-height: 30px;
}

.plan-offer-list li {
    font-size: 16px;
    margin: 10px;
    text-align: justify;
}

p.description-police.mt--10.mb-2.mx-2 {
    font-size: 1.5rem;
    text-align: justify;
    padding-left: 6rem;
}

p.description-police.mb-2 {
    font-size: 1.5rem;
    text-align: justify;
    line-height: 30px;
}

.container.pb-6 {
    padding-bottom: 4rem;
    font-size: 1.8rem;
    text-align: start;
    margin-left: 16rem;
}

a.rbt-btn.btn-border.btn-sm.mt--40 {
    border: 1px solid #e6e3f1;
    padding: 1rem;
    color: #8f8585;
    text-decoration: none;
    border-radius: 4px;
}

button.accordion-button.btn.btn-link {
    font-size: 1.8rem;
    text-decoration: none;
    // margin-top: 1rem;
    // margin-bottom: 2rem;
}

.card-acc {
    font-size: 1.5rem;
    text-align: justify;
    padding: 1rem 1rem 1rem;
    line-height: 32px;
}

.accordion-button:focus {
    border: none;
    box-shadow: none;
}

.acc-border {
    border: 1px solid #dbdbdb;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

button.accordion-button.collapsed.btn.btn-link {
    font-size: 1.8rem;
    text-decoration: none;
    font-weight: 500;
}

.p-4.add.container {
    text-align: justify !important;
    padding: 2rem !important;
}

.whatsapp-chat-title {
    font-size: 19px;
    font-weight: 600;
}

.description-police {
    font-size: 1.6rem;
}

.title-police {
    font-size: 1.8rem;
}

@media only screen and (max-width: 768px) {

    .whatsapp-chat-title {
        font-size: 15px;
        font-weight: 600;
    }

}

.page-link {
    color: #d044ed !important;
}

.active>.page-link,
.page-link.active {
    z-index: 3;
    color: #fff !important;
    background-color: #d044ed !important;
    border-color: #d044ed !important;
}

.reviewa-banner-container {
    position: relative;
    text-align: center;
    color: white;
    overflow: hidden;
}

.banner {
    position: relative;
    width: 100%;
    height: auto;
}

.reviewa-banner {
    width: 100%;
    height: auto;
    display: block;
}

.reviewa-banner.mob {
    display: none;
}

.review-widgets-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 20px;
    gap: 16px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
    max-width: 90%;
    flex-direction: row;
}

.trustpilot-widget-container,
.stjr-review-button {
    margin-right: 10px;
}

i.pay-icon {
    font-size: 30px;
    color: #fff;
}

.react-player-wrapper {
    position: relative;
}

.play-button-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.play-button {
    background: rgba(255, 255, 255, 0.8);
    border: none;
    padding: 10px 20px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 50%;
    transition: background 0.3s;
}

.play-button:hover {
    background: rgba(255, 255, 255, 1);
}

.ptb-inner {
    padding-bottom: 6rem !important;
    padding-top: 3.5rem !important;
}

@media (max-width: 768px) {
    .review-widgets-container {
        flex-direction: column;
        align-items: center;
    }

    .trustpilot-widget-container,
    .stjr-review-button {
        width: 100%;
        margin-bottom: 10px;
    }

    .trustpilot-widget {
        width: 100%;
    }

    .reviewa-banner.mob {
        display: block;
    }

    .reviewa-banner.des {
        display: none;
    }

    .reviewa-banner {
        padding-bottom: 28rem;
    }

    .student-stories-container.banner {
        margin: 0;
    }

    .review-dashboard {
        display: block;
        gap: 16px;
    }
}

.tp-widget-wrapper {
    text-align: center;
    margin-left: 30% !important;
}