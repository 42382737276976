@import "../../Styles/variables";


// above Delete
.banner-main-wrapper {
  height: calc(75vh - 80px);
  position: relative;
  display: flex;
  justify-content: center;

  .banner-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
    z-index: -1;
  }

  .banner-content-pc {
    &-title-wrapper {

      .title-que-banner {
        font-size: 3.5rem !important;
      }

      .title {
        font-size: 3.6rem;
        line-height: 5rem;
        color: $color-primary-900;
        font-family: $font-secondary;

        .gradient-title {
          font-weight: $f-semi-bold;
          ;
          font-family: $font-primary;
          background: $primary-gradient;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: rgba(255, 255, 255, 0.001);
        }
      }
    }

    &-subtitle {
      font-size: 1.5rem !important;
      font-weight: $f-medium;
      line-height: 2.5rem;
      color: $color-gray-500;
    }

    &-available-text {
      font-size: 3rem;
      line-height: 7.2rem;
      color: $color-primary-900;
      font-weight: $f-semi-bold;
      ;
    }
  }

  .pc-subtitle-pd {
    padding: 0 25px;
  }

  .clg-document {
    width: 100%;
    padding: 3rem 2rem;
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
    border-top: 1px solid #f1f0f3;
    box-shadow: 0 4px 16px rgba(8, 10, 18, 0.1);
    clip: rect(0px, 200vw, 0, 0px);
    transition: opacity 0.2s linear, clip 0.6s linear, visibility 0s 0.2s;
    transform: translateZ(0);
  }

  .clg-document-box {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .box-border>div {
    border-right: 2px solid #afabab;

    &:last-child {
      border-right: none !important;
    }
  }

  .clg-document-box img {
    width: 16%;
    height: auto;
  }

  .clg-document-box p span {
    font-size: 1.7rem;
  }

  .clg-document-box p,
  .clg-document-box i {
    color: #6754E9;
    font-size: 1.5rem;
    font-weight: 600;
    margin-left: 1rem;
  }

  .clg-document-box:hover p,
  .clg-document-box:hover i {
    color: #D743ED;
  }


  @media only screen and (max-width: 425px) {

    .title h1 {
      // padding-top: 10px;
      line-height: 30px;
    }

    /* Styles for mobile phones */
    .banner-content-title-wrapper .title {
      // font-size: 3rem;
      // line-height: 3rem;
      font-size: 1.7rem !important;
      line-height: 2.5rem;

    }

    .librarybanner-title {
      font-size: 17px;
      font-weight: 600;
      line-height: 3rem;
    }

    .title strong {
      font-weight: 600;
      font-size: 2.6rem !important;
    }

    .banner-content-subtitle {
      font-size: 1.3rem;
    }

    .banner-content-available-text {
      font-size: 2rem;
    }


    .text-wight-que-banner {
      font-weight: 550;
      font-size: 1rem;
      margin-top: 5px;
    }



  }



  @media only screen and (min-width:602px) and (max-width: 768px) {

    .banner-content-pc {
      padding: 3rem 1rem;
    }

    .banner-content-title-wrapper .title {
      font-size: 4.2rem;
      line-height: 5.1rem;
    }

    .librarybanner-title {
      font-size: 17px;
      font-weight: 600;
      line-height: 3rem;
    }

    .banner-content-subtitle {
      font-size: 1.2rem;
    }

    .banner-content-available-text {
      font-size: 3rem;
    }

    .title strong {
      font-weight: 600;
      font-size: 3.6rem !important;
    }

    button {
      padding: 0.7rem 4.3rem;
    }

    .clg-document-box {

      padding: 2px 2px;
    }

    .box-border>div {
      border-right: 2px solid #afabab !important;

      &:nth-child(even) {
        border-right: none !important;
      }

      &:nth-child(-n+2) {
        margin-bottom: 10px;
      }
    }


  }

  @media only screen and (min-width: 768px) and (max-width: 1023px) {

    .banner-content-title-wrapper .title {
      font-size: 6rem;
      line-height: 6rem;
    }

    .banner-content-subtitle {
      font-size: 2.2rem;
    }

    button {
      padding: 0.8rem 4.5rem;
    }
  }

  @media only screen and (min-width: 1024px) and (max-width: 1199px) {

    .banner-content-title-wrapper .title {
      font-size: 6.5rem;
      line-height: 6.5rem;
    }

    .banner-content-subtitle {
      font-size: 2.4rem;
    }
  }
}


.title strong {
  font-weight: 600;
}


.img-fluid-home {
  max-width: 70%;
  height: auto;
  display: block;
  margin: 0 auto;
}

@media (max-width: 600px) {
  .img-fluid-home {
    max-width: 100%;
  }

  .clg-document {
    padding: 1.5rem 0.5rem !important;
  }

  .box-border {
    padding: 0px 1rem;
  }

  .box-border>div {
    border-bottom: 2px solid #afabab !important;
    border-right: none !important;
    padding-bottom: 14px !important;
    margin-bottom: 10px;

    &:last-child {
      border-bottom: none !important;
      margin-bottom: 0px !important;
      padding-bottom: 0px !important;
    }
  }

}

.title.mt-4 {
  margin-top: 10rem !important;
}

.banner-main-wrapper .banner-content-pc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 80px 10px;
  width: 85rem;
}

@media (max-width: 767px) {
  .banner-main-wrapper .banner-content-pc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 35px 10px !important;
    width: 85rem;
  }
}