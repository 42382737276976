.exam-complited {
  background-color: #25C73D;
  padding: 8px 8px;
  border-radius: 5px;
}

.exam-processing {
  background-color: #FF3535;
  padding: 8px 8px;
  border-radius: 5px;
}

.question-bank-history {
  background-color: #E5F1FB;
  margin-top: 8rem;
  padding-left: 14px;
  padding-right: 14px;
  padding-top: 2rem;
  padding-bottom: 8rem;
}

.question-bank-papers {
  background-color: #fff;
  border-radius: 10px;
  border: solid 1px #C7C8C9;
}

.que-bank-text {
  color: #afabab;
}

.que-course-name {
  font-size: 12px;
}

.que-course-name span {
  color: #6754E9;
}

.que-course-sub1 {
  font-size: 13px;
}

.que-course-sub2 {
  font-size: 12px;
}

.que-course-right {
  line-height: 25px;
  padding: 0px 20px 0px 0px;
}

@media only screen and (max-width: 767px) {
  .exam-complited {
    margin-top: 2rem;
  }

  .que-course-right {
    line-height: 25px;
    padding: 0px 15px;
    text-align: justify;
  }

  .que-course-btn {
    margin-top: 15px;
  }

  .latest-exam {
    line-height: 24px !important;
    font-size: 15px !important;
  }
  .mobile-none{
    display: none !important;
  }
}