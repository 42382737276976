@import "../../Styles/variables.scss";

.db-university-card {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px; 
    margin-bottom: 20px; 

    .db-suggestion-card {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
        width: 90% !important;
        background-color: #e4e5eb;
        padding: 15px 10px;
        border-radius: 10px;
        margin: 0 auto;
    }
    
    .db-suggestion-card div .uni-name p {
        font-size: 14px;
        font-weight: 500;
    }
    
    .db-suggestion-card div .uni-name p span {
        font-size: 12px;
        color: #757575;
    }
    
    .db-suggestion-card .rating-stars {
        border-right: 1px solid rgb(173, 173, 173);
        padding-right: 10px;
    }

    @media (min-width: 1200px) {
        grid-template-columns: repeat(2, 1fr); 
        .db-suggestion-card {
            width: 95% !important;
        }
    }
}

.db-special-study-notes, .db-practice-paper, .db-question-bank{
    margin-bottom: 10rem !important;
}

.db-title-search {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 25px;
}

.db-prac-paper-search {
    position: relative;
}

.db-prac-paper-search input {
    border: 1px solid #D444ED;
    padding: 5px 20px 5px 20px;
    width: 225px;
}

.db-prac-paper-search i {
    color: #D444ED;
    position: absolute;
    top: 25%;
    right: 5%;
}

.db-search-icon{
    color: #D444ED;
    position: absolute;
    top: 25%;
    right: 5%;
}

@media (min-width: 1200px) {

    .db-special-study-notes, .db-practice-paper, .db-question-bank{
        margin-bottom: 13rem !important;
    }
    

    .db-prac-paper-search input {
        width: 400px;
    }
}

@media (max-width:768px) {

    .db-prac-paper-search input {
        border: 1px solid #D444ED;
        padding: 5px 20px 5px 20px;
        width: 300px;
    }


    .db-suggestion-card div .uni-name p span {
        font-size: 10px !important;
        color: #757575;
    }

    .db-suggestion-card div .uni-name p {
        font-size: 10px !important;
        font-weight: 500;
    }

    .db-suggestion-card {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-around;
        background-color: #F1F2F8;
        padding: 15px 10px;
        border-radius: 10px;
        width: 90% !important;
        text-align: start;
    }
}

@media (min-width:600px) and (max-width:768px) {
    .db-suggestion-card {
        width: 100% !important;
    }

    .db-title-search {
    display: flex;
    justify-content: space-between;
        flex-direction: column !important;
        align-items: start !important;
    }

    
}

@media (max-width:600px) {
    .db-suggestion-card {
        width: 100% !important;
    }
    .db-title-search {
        display: flex;
        justify-content: space-between;
            flex-direction: column !important;
            align-items: start !important;
    }
}



@media (max-width:426px) {

    .db-title-search {
        display: flex;
        flex-direction: column;
    }

    .db-prac-paper-search input {
        border: 1px solid #D444ED;
        padding: 5px 20px 5px 20px;
        width: 250px;
    }


    .db-suggestion-card div .uni-name p span {
        font-size: 10px !important;
        color: #757575;
        }
        
            .db-suggestion-card div .uni-name p {
                font-size: 10px !important;
                font-weight: 500;
            }

            .db-suggestion-card {
                display: flex;
                gap: 10px;
                align-items: center;
                justify-content: space-around;
                background-color: #F1F2F8;
                padding: 15px 10px;
                border-radius: 10px;
            }
}